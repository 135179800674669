import React from 'react'
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import CallToAction from "../components/CallToAction"
import { HeroTitle, Section, BreadCrumb } from "../components/Section"
import CaseStudiesList from "../components/CaseStudiesList"

class CaseStudiesPage extends React.Component {
	constructor(props) {
		super(props)
	}
	render() {
        const caseStudyData = this.props.data.allContentfulCaseStudy.edges
		return(
			<Layout location={this.props.location}>
            <SEO title="Case Studies" description="Case Studies"/>
            <Section pt="250px" pb="60px" mtp="170px">
                <div className="container">
                    <BreadCrumb top="-40px" mTop="-40px"><Link to="/">Home</Link> / <span>Case Studies</span></BreadCrumb>
                    <HeroTitle>Case Studies</HeroTitle>                 
                </div>
            </Section>
            <Section pt="81px" pb="103px" bg="#F4FBFF">
                <div className="container">
                    <CaseStudiesList data={caseStudyData} />
                </div>
            </Section>
            <CallToAction />
	    </Layout>
		)
	}
}
		
export default CaseStudiesPage

export const pageQuery = graphql`
  query CaseStudiesPageTemplateQuery {
    allContentfulCaseStudy(sort: {fields: createdAt, order: DESC}) {
        edges {
            node {
                title
                slug
                thumbnail {
                    fluid(maxWidth: 980) {
                        aspectRatio
                        base64
                        sizes
                        src
                        srcSet
                        srcSetWebp
                        srcWebp
                    }
                }
                content {
                    childMarkdownRemark {
                        excerpt
                    }
                }
            }
        }
    }
  }
`

